import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import errorMessage from '../Helper/ErrorMessage'
import Service from '../Networking/Networkingutils'
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from "moment";
import { withSnackbar } from 'notistack';
import { Container, Row, Col } from 'react-grid';


const token = getCookie("bb_ts_token");

class LeaveCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            noData: false,
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.fetchData();
        } else if (this.props.id) {
            this.userLeaveCard();
        } else {
            this.setState({
                data: this.props.values,
                isLoading: false,
            })
        }
    }

    fetchData() {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?uid=${this.props.value}&start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length) {
                    this.setState({
                        data: res.data[0],
                        isLoading: false,
                    })
                    this.props.onHandleData(res.data);
                } else {
                    this.setState({
                        noData: true,
                    })
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    userLeaveCard() {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?uid=${this.props.id}&start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length) {
                    this.setState({
                        data: res.data[0],
                        isLoading: false,
                    })
                } else {
                    this.setState({
                        noData: true,
                    })
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? ((!this.state.noData) ? <Box marginTop="50px" flexDirection="row" justifyContent="center" display="flex" padding="15px"><CircularProgress /></Box> : <Box marginTop="50px" flexDirection="row" justifyContent="center" display="flex" padding="15px">Data unavailable</Box>) : (
                    <Box marginTop="60px" flexDirection="row" display="flex" padding="15px" >
                        <Container fluid>
                            <Row>
                                <Col>

                                    <Card style={{ background: '#FFB74D' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    CL Eligible
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >

                                                    {this.state.data.this_month_sick_leave.toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: '#DCE775' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center">
                                                <Typography >
                                                    Availed CL
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {this.state.data.sick_days}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ background: '#FFF176' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    CL Balance
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {(this.state.data.this_month_sick_leave - this.state.data.sick_days).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: '#ccffff' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    EL C/F
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    {((this.state.data.annaul_carry_forward)).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: '#4DB6AC' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    EL Eligible
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    {((this.state.data.this_month_annual_leave + this.state.data.annaul_carry_forward)).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: '#AED581' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center">
                                                <Typography >
                                                    Availed EL
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {this.state.data.leave_days}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ background: '#EF9A9A' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    EL Balance
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {((this.state.data.this_month_annual_leave + this.state.data.annaul_carry_forward) - this.state.data.leave_days).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                {this.state.data.maternity_days !== 0 ?
                                    <Col>
                                        <Card style={{ background: '#80b3ff' }}>
                                            <CardContent>
                                                <Box flexDirection="row" display="flex" justifyContent="center" >
                                                    <Typography >
                                                        Maternity/Paternity
                                                    </Typography>
                                                </Box>
                                                <Box flexDirection="row" display="flex" justifyContent="center" >
                                                    <Typography >
                                                        {(this.state.data.maternity_days).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>

                                    </Col>
                                    : null}
                                {this.state.data.lop_days !== 0 ?
                                    <Col>
                                        <Card style={{ background: '#ff6666' }}>
                                            <CardContent>
                                                <Box flexDirection="row" display="flex" justifyContent="center" >
                                                    <Typography >
                                                        LOP
                                                    </Typography>
                                                </Box>
                                                <Box flexDirection="row" display="flex" justifyContent="center" >
                                                    <Typography >
                                                        {(this.state.data.lop_days).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                    : null}
                            </Row>
                        </Container>

                    </Box >
                )}

            </div >
        )
    }
}

export default withSnackbar(LeaveCard)


import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home/Home'
import { SnackbarProvider, withSnackbar } from 'notistack';
import { deleteCookie, getCookie } from './Helper/CookieHelper'
import Service from './Networking/Networkingutils'
import { CircleToBlockLoading } from 'react-loadingg';
import RequestLink from './LeaveManagementSystem/NewTabs/RequestLink';
import DetailView from './LeaveManagementSystem/NewTabs/DetailView';
import errorMessage from './Helper/ErrorMessage';
import UserLeave from './LeaveManagementSystem/UserLeave';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const token = getCookie("bb_ts_token");

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: '#007681',
      // #005D99
    },
    secondary: {
      main: '#EEBE86',
      // #0091B5
    },
    ternary: {
      main: '#ff3333',
    },
    red: {
      main: '#ff3333',
    },
  }
})

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    // console.log(getCookie("bb_ts_token"))
    //  debugger
    if (getCookie("bb_ts_token") && getCookie("bb_ts_user")) {
      var access = JSON.parse(getCookie("bb_ts_access"))
      if (access && access.access && access.access.l18a && access.access.l18a.l18a_b) {
        this.checkToken();
      } else if (access && access.access && access.access.l18a && access.access.l18a.l18a_a) {
        if (!window.location.href.includes('/user/leave')) {
          window.location.href = '/user/leave'
        } else {
          this.checkToken();
        }

      } else {
        if (window.location.hostname.includes("lms.bbparam.com")) {
          window.location.href = 'https://bbparam.com/lms/auth/?url=' + window.location.href
        }

      }
    } else {
      if (window.location.hostname.includes("lms.bbparam.com")) {
        window.location.href = 'https://bbparam.com/lms/auth/?url=' + window.location.href
      }
    }


  }

  checkToken() {
    Service.get(`/bb/api/token/valid/check/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        // console.log(res)
        this.setState({ isLoading: false })
      })
      .catch(error => {
        console.log(error)
        if (error === undefined) {
          errorMessage(this.props, error)
        } else if (error && error.status === 401) {
          deleteCookie("bb_ts_token");
          if (window.location.hostname.includes("lms.bbparam.com")) {
            window.location.href = 'https://bbparam.com/lms/auth/?url=lms.bbparam.com'
          }
        }
      });
  }

  render() {

    return (
      <div>
        {
          (this.state.isLoading) ? (
            <CircleToBlockLoading color='#007681' />
          ) : (
            <ThemeProvider theme={bbColorTheme}>
              <SnackbarProvider maxSnack='3'>
                <BrowserRouter>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/user/leave" exact component={UserLeave} />
                    <Route path="/detail/:id" exact component={DetailView} />
                    <Route path="/request/:u_id/:r_id" exact component={RequestLink} />
                  </Switch>
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProvider>
          )
        }
      </div>
    )
  }
}

export default withSnackbar(App);
import React, { Component } from 'react'
import errorMessage from '../../Helper/ErrorMessage'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper'
import moment from "moment";
import Service from '../../Networking/Networkingutils'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { CircleToBlockLoading } from 'react-loadingg';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, IconButton } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LaunchIcon from '@material-ui/icons/Launch';
import Tooltip from '@material-ui/core/Tooltip';
import 'react-big-calendar/lib/css/react-big-calendar.css'

const token = getCookie("bb_ts_token");
const localizer = momentLocalizer(moment)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CalenderView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data1: [],
            ids: [],
            tabledata: [],
            BankLeavedata: [],
            isProgress: true,
            isLoading: true,
            isLoading1: true,
            isLoading2: false,
            openDialogue: false,
            selectedData: null,
            start_date: moment().startOf('month').startOf('week'),
            end_date: moment().endOf('month').endOf('week'),
        }
    }

    componentDidMount() {
        this.fetchData(this.state.start_date, this.state.end_date);

        Service.get(`/bb/api/bank/holiday/?year=${moment().format("YYYY")}&office_loc=${1}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    BankLeavedata: res.data,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    isReject(date) {
        var date1 = moment();
        var date2 = moment(date).utc().startOf('date').add(15, 'days');
        return date1.isAfter(date2);
    }

    onRangeChange = (range) => {
        if (range[0]) {
            this.setState({ start_date: moment(range[0]), end_date: moment(range[6]), isLoading1: true });
            this.fetchData(moment(range[0]), moment(range[6]))
        } else {
            this.fetchData(range.start, range.end)
            this.setState({ isLoading1: true })
        }
    }

    fetchData(start, end) {
        var start_date = moment(start).format('DD-MM-YYYY HH:mm:ss')
        var end_date = moment(end).format('DD-MM-YYYY HH:mm:ss')
        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;

        Service.get(`/bb/api/user/report/view/members_workload/?uid=${lead_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var ids = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (!ids.includes(element.uid_data.id)) {
                        ids.push(element.uid_data.id)
                    }
                }
                this.setState({
                    ids: ids,
                    isLoading: false,
                    isLoading1: false
                })

                if (this.state.ids.length !== 0) {
                    Service.get(`/bb/api/team/tasks/?uid=${this.state.ids.toString()}&start_date=${start_date}&end_date=${end_date}&tid=66,67,77,78`, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {

                            var datas = [];
                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                //  if(element.is_submitted===1){
                                if (element.is_approved === 0) {
                                    datas.push({
                                        id: element.id,
                                        user_id: element.uid,
                                        title: element.uname_first + ", " + element.actual_hours + " hours - [ Pending ]",
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_approved: element.is_approved,
                                        is_submitted: element.is_submitted,
                                        tname: element.tname,
                                        hours: element.actual_hours,
                                        comments: element.comments,
                                        name: element.uname_last + ", " + element.uname_first,
                                        isLoading: false,
                                        isButtonDisabled: false,
                                        isButtonDisabled1: false,
                                    })
                                } else if (element.is_approved === 1) {
                                    datas.push({
                                        id: element.id,
                                        user_id: element.uid,
                                        title: element.uname_first + ", " + element.actual_hours + " hours - [ Approved ]",
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_approved: element.is_approved,
                                        is_submitted: element.is_submitted,
                                        tname: element.tname,
                                        hours: element.actual_hours,
                                        comments: element.comments,
                                        name: element.uname_last + ", " + element.uname_first,
                                        isLoading: false,
                                        isButtonDisabled: false,
                                        isButtonDisabled1: false,
                                    })
                                }
                                else if (element.is_approved === 2) {
                                    datas.push({
                                        id: element.id,
                                        user_id: element.uid,
                                        title: element.uname_first + ", " + element.actual_hours + " hours - [ Rejected ]",
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_approved: element.is_approved,
                                        is_submitted: element.is_submitted,
                                        tname: element.tname,
                                        hours: element.actual_hours,
                                        comments: element.comments,
                                        name: element.uname_last + ", " + element.uname_first,
                                        isLoading: false,
                                        isButtonDisabled: false,
                                        isButtonDisabled1: false
                                    })
                                }
                                //  }
                            }
                            this.setState({
                                data: datas,
                                isLoading: false,
                                isLoading1: false,
                            })
                        })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)

                        });
                    this.lmsapproval()
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    lmsapproval() {
        Service.get(`/bb/api/lms/approval/lms_leave_details/?user=${this.state.ids.toString()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let j = 0; j < res.data.length; j++) {
                    const element = res.data[j];
                    element.isLoading = false;
                    element.isButtonDisabled = false;
                    element.isButtonDisabled1 = false;
                }

                this.setState({
                    data1: res.data,
                    isLoading: false,
                    isLoading1: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            })
    }

    fetchID(id) {
        Service.get(`/bb/api/lms/approval/?team_tasks=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ tabledata: res.data, isProgress: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    linkButton = (value, data) => {
        if (data !== null && data !== undefined) {
            var id = data.requested_to
            var u_id = value.user_id;
            window.open("/request/" + u_id + "/" + id)
            this.setState({ openDialogue: false })
        } else if (data === undefined && value.is_approved === 2) {
            this.props.enqueueSnackbar('This Leave request is Rejected !', {
                variant: 'info'
            });
        } else {
            this.props.enqueueSnackbar('Not Yet Submitted', {
                variant: 'info'
            });
        }
    }

    approval = (event, data) => {
        var lists = this.state.data1
        var card_array = [];
        data.is_approved = 1;
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks === data.id) {
                card_array.push(lists[i].id)
                lists[i].isLoading = true;
            }
        }

        this.setState({ data1: lists, isLoading2: true })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 1,
            },
        })
            .then(res => {
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].team_tasks === data.id) {
                        lists[i].isLoading = false;
                    }
                }
                this.setState({ data1: lists, isLoading2: false })
                this.props.enqueueSnackbar('Approved Successfully', {
                    variant: 'success'
                });
                this.lmsapproval()
                this.showButtons(data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    reject = (event, data) => {
        var lists = this.state.data1
        var card_array = [];
        data.is_approved = 2;
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks === data.id) {
                card_array.push(lists[i].id)
                lists[i].isLoading = true;
            }
        }

        this.setState({ data1: lists, isLoading2: true })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 2,
            },
        })
            .then(res => {
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].team_tasks === data.id) {
                        lists[i].isLoading = false;
                    }
                }
                this.setState({ data1: lists, isLoading2: false })
                this.props.enqueueSnackbar('Rejected Successfully', {
                    variant: 'success'
                });
                this.lmsapproval()
                this.showButtons(data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    showButtons(data) {
        var lists = this.state.data1
        var is_exist = false
        var name = ''
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks !== null) {
                if (lists[i].team_tasks === data.id) {
                    is_exist = true;
                    name = lists[i].replied_by_data ? lists[i].replied_by_data.first_name : null
                }
            } else if (lists[i].snapshot.id === data.id) {
                name = lists[i].replied_by_data ? lists[i].replied_by_data.first_name : null
                is_exist = true

            }
        }

        if (is_exist) {
            if (data.is_approved === 0) {
                return <Box display="flex" justifyContent="center" bgcolor="background.paper">
                    <Button
                        disabled={data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        onClick={e => this.approval(e, data)}>
                        Approve
                    </Button>

                    <Button
                        disabled={this.isReject(data.start) || data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        onClick={e => this.reject(e, data)}>
                        Reject
                    </Button>
                </Box>
            } else if (data.is_approved === 1) {
                return (
                    <Box display="flex" justifyContent="center" >

                        <Box display="flex" alignItems="center" >
                            <Box style={{ color: "green" }} marginTop="5px"><CheckCircleIcon /></Box>
                            <Box style={{ color: "green" }} marginLeft="5px">Approved by {name}</Box>
                        </Box>

                        {(this.isReject(data.start)) ? (null) : (
                            <Button
                                disabled={this.isReject(data.start) || data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                                onClick={e => this.reject(e, data)}>
                                Reject
                            </Button>
                        )}
                    </Box>
                )
            } else if (data.is_approved === 2) {
                return <Box display="flex" justifyContent="center" >
                    <Box display="flex" alignItems="center" >
                        <Box style={{ color: "red" }} marginTop="5px"><CancelIcon /></Box>
                        <Box style={{ color: "red" }} marginLeft="5px">Rejected by {name}</Box>
                    </Box>
                </Box>
            }
        } else {
            return <Box display="flex" justifyContent="center" >
                <Box display="flex" alignItems="center" >
                    <Box marginBottom="1px" marginLeft="3px">Not Yet Submitted </Box>
                </Box>
            </Box>
        }
    }

    render() {

        const handleOpenDialogue = (data) => {
            this.setState({ openDialogue: true, selectedData: data })
            this.fetchID(data.id)
        }

        const handleCloseDialogue = () => {
            this.setState({ openDialogue: false })
        }

        const customDayPropGetter = (date) => {
            var BankLeaveDates = this.state.BankLeavedata
            for (let i = 0; i < BankLeaveDates.length; i++) {
                const element = BankLeaveDates[i]
                var date1 = moment(date).format('YYYY-MM-DD')
                if (date1 === element.leave_date) {
                    return {
                        style: {
                            backgroundColor: '#ffe6e6',
                        },
                    }
                }
            }
        }
        return (
            <div >
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <div>
                        {(this.state.isLoading1) ? (
                            <CircleToBlockLoading color='#007681' />
                        ) : (null)}
                        <Calendar
                            popup
                            localizer={localizer}
                            events={this.state.data}
                            onSelectEvent={(data) => handleOpenDialogue(data)}
                            defaultView={Views.MONTH}
                            views={[Views.MONTH, Views.WEEK]}
                            startAccessor="start"
                            endAccessor="end"
                            onRangeChange={(date) => this.onRangeChange(date)}
                            style={{ height: '88vh' }}
                            eventPropGetter={data => data.is_approved === 0 && data.is_submitted === 1 ? ({ style: { backgroundColor: '#f09e00' } }) : data.is_approved === 1 ? ({ style: { backgroundColor: '#00aa39' }, }) : data.is_approved === 2 ? ({ style: { backgroundColor: '#585858' }, }) : data.is_submitted === 0 ? ({ style: { backgroundColor: '#005D99' } }) : (null)}
                            dayPropGetter={customDayPropGetter}
                        />
                    </div>
                )}

                <Dialog open={this.state.openDialogue} onClose={handleCloseDialogue} TransitionComponent={Transition}>
                    <DialogTitle id="customized-title" onClose={handleCloseDialogue}>
                        Leave Details
                    </DialogTitle>
                    <DialogContent>
                        {(this.state.selectedData) ? (
                            <div>
                                <Typography>
                                    <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="5px" >
                                        <Box fontWeight="fontWeightBold">Name : </Box>
                                        <Box>
                                            {this.state.selectedData.name}
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography>
                                    <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="10px">
                                        <Box fontWeight="fontWeightBold">Leave Type : </Box>
                                        <Box>
                                            {this.state.selectedData.tname}
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography>
                                    <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="10px">
                                        <Box fontWeight="fontWeightBold">Hours : </Box>
                                        <Box>
                                            {this.state.selectedData.hours} hours
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography>
                                    <Box display="flex" flexDirection="row" style={{ width: 250 }} marginTop="10px">
                                        <Box fontWeight="fontWeightBold">Comments : </Box>
                                        <Box>
                                            {this.state.selectedData.comments}
                                        </Box>
                                    </Box>
                                </Typography>

                                <Box display="flex" marginTop="15px" bgcolor="background.paper" justifyContent='center'>
                                    <Box display="flex" flexDirection="row" justifyContent="center" marginLeft="5px" >
                                        {(this.state.isLoading2) ? <CircularProgress color='primary' size={25} style={{ marginTop: '10px' }} /> : this.showButtons(this.state.selectedData)}
                                    </Box>
                                    <Box display="flex" flexDirection="row" justifyContent="center" marginLeft="10px">
                                        {this.state.isProgress === true ? <CircularProgress color="secondary" /> : (
                                            <Tooltip title="GO TO">
                                                <IconButton color="primary" onClick={e => this.linkButton(this.state.selectedData, this.state.tabledata[0])} >
                                                    <LaunchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                            </div>
                        ) : (null)}
                    </DialogContent>

                    {(this.state.selectedData) ? (
                        <DialogActions >
                            <Button onClick={handleCloseDialogue} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    ) : (null)}

                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(CalenderView)
import React, { Component } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Team from '../LeaveManagementSystem/LeaveinfoTabs/Team';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import RequestReceived from '../LeaveManagementSystem/LeaveinfoTabs/RequestReceived';
import Log from '../LeaveManagementSystem/LeaveinfoTabs/Log';
import CalenderView from '../LeaveManagementSystem/LeaveinfoTabs/CalenderView'
import Management from '../LeaveManagementSystem/LeaveinfoTabs/Management';
import CarryForward from '../LeaveManagementSystem/LeaveinfoTabs/CarryForward';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CardMedia from '@material-ui/core/CardMedia';
import { Avatar, Tooltip } from '@material-ui/core';
import BankHolidays from '../LeaveManagementSystem/LeaveinfoTabs/BankHolidays';
import BankHolidaysUK from '../LeaveManagementSystem/LeaveinfoTabs/BankHolidaysUK';
import LvsSearchByDates from '../LeaveManagementSystem/LvsSearchByDates/LvsSearchByDates';

const drawerWidth = 240;
const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    orange: {
        backgroundColor: 'orange'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(3),
    },
});

// const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// console.log(access)
const user = JSON.parse(getCookie("bb_ts_user"));

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8"
        }
    }
})(ListItem);

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#007681',
        },
        secondary: {
            main: '#EEBE86',
        },
    }
})

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewClass: '',
            openDrawer: false,
            menus: [],
            isLoading: true,
        }
    }


    componentDidMount() {
        this.createMenu();
    }

    createMenu() {

        var accessBuild = [];
        var defaultView = '';
        var hashDone = false;

        var viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ');

        if (access.access.l18a && access.access.l18a.l18a_b) {

            if (viewHash === 'Team') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Team')
            if (defaultView === '') {
                defaultView = 'Team'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_b) {

            if (viewHash === 'Calendar') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Calendar')
            if (defaultView === '') {
                defaultView = 'Calendar'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_b) {

            if (viewHash === 'Leave By Dates') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Leave By Dates')
            if (defaultView === '') {
                defaultView = 'Leave By Dates'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_b) {
            if (viewHash === 'Request Received') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Request Received')
            if (defaultView === '') {
                defaultView = 'Request Received'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_c) {
            if (viewHash === 'Management') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Management')
            if (defaultView === '') {
                defaultView = 'Management'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_c) {
            if (viewHash === 'Log') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Log')
            if (defaultView === '') {
                defaultView = 'Log'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_c) {
            if (viewHash === 'Carry Forward') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Carry Forward')
            if (defaultView === '') {
                defaultView = 'Carry Forward'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_c) {
            if (viewHash === 'Bank Holidays(India)') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Bank Holidays(India)')
            if (defaultView === '') {
                defaultView = 'Bank Holidays(India)'
            }
        }

        if (access.access.l18a && access.access.l18a.l18a_c) {
            if (viewHash === 'Bank Holidays(UK)') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Bank Holidays(UK)')
            if (defaultView === '') {
                defaultView = 'Bank Holidays(UK)'
            }
        }

        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView
            })
        }
    }
    render() {

        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({ openDrawer: true })
        };
        const avatarClick = (e) => {
            alert("Login Information:\nLogin Provider: BB PaRaM \nUser: " + user.last_name + ', ' + user.first_name + "\nEmail: " + user.email)
        }
        const handleDrawerClose = () => {
            this.setState({ openDrawer: false })
        };

        const handleViewChange = (e, val) => {
            var pageView = window.location.pathname + "/#" + val;
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        return (

            <ThemeProvider theme={bbColorTheme}>
                <div className={classes.root} >

                    <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: this.state.openDrawer })}>
                        <Toolbar>
                            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, { [classes.hide]: this.state.openDrawer, })}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap className={classes.title}>
                                <Box display="flex" alignItems="flex-end">
                                    Leave Management System<Box style={{ marginLeft: '5px' }} fontSize={10} >Powered by PaRaM</Box>
                                </Box>

                            </Typography>
                            <Tooltip title="Profile">
                                <Avatar style={{ marginRight: '0px' }} onClick={e => avatarClick()} className={classes.orange}>
                                    <Box fontSize={16} fontWeight="fontWeightBold"> {user.last_name.charAt(0) + user.first_name.charAt(0)}</Box>
                                </Avatar>
                            </Tooltip>

                        </Toolbar>
                    </AppBar>

                    <Drawer variant="permanent" className={clsx(classes.drawer, {
                        [classes.drawerOpen]: this.state.openDrawer,
                        [classes.drawerClose]: !this.state.openDrawer,
                    })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.openDrawer,
                                [classes.drawerClose]: !this.state.openDrawer,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <CardMedia
                                component="img"
                                alt="Balfour Beatty"
                                image={process.env.PUBLIC_URL + '/bb.png'}
                                title="Balfour Beatty"
                                style={{ width: '170px' }}
                            />
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />

                        <List>
                            {this.state.menus.map((text) => (
                                <Tooltip title={text} aria-label={text} key={text}>
                                    <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                        <ListItemIcon>{text === 'Calendar' ? <CalendarTodayIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Team' ? <PeopleIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Request Received' ? <SystemUpdateAltIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Management' ? <GroupAddIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Log' ? <AllInboxIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Carry Forward' ? <CardTravelIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Bank Holidays(India)' ? <WorkOffIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Bank Holidays(UK)' ? <WorkOffIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Leave By Dates' ? <DateRangeIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : (null)}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </StyledListItem>
                                </Tooltip>
                            ))}
                        </List>

                        <Divider />

                    </Drawer>

                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {
                            (this.state.viewClass === "Team") ? (
                                <Team />
                            ) : (this.state.viewClass === "Calendar") ? (
                                <CalenderView />
                            ) :
                                (this.state.viewClass === "Request Received") ? (
                                    <RequestReceived />
                                ) : (this.state.viewClass === "Management") ? (
                                    <Management />
                                ) : (this.state.viewClass === "Log") ? (
                                    <Log />
                                ) : (this.state.viewClass === "Carry Forward") ? (
                                    <CarryForward />
                                ) : (this.state.viewClass === "Bank Holidays(India)") ? (
                                    <BankHolidays off_loc={1} />
                                ) : (this.state.viewClass === "Bank Holidays(UK)") ? (
                                    <BankHolidaysUK off_loc={0} />
                                ) : (this.state.viewClass === "Leave By Dates") ? (
                                    <div>
                                        <LvsSearchByDates />
                                    </div>
                                ) : (null)
                        }
                    </main>
                </div>
            </ThemeProvider>
        );
    }
}

export default withStyles(useStyles)(Home);
import React, { Component } from 'react'
import MaterialTable from 'material-table';
import { CircleToBlockLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/Networkingutils'
import errorMessage from '../../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import moment from "moment";
import { CsvBuilder } from 'filefy';

const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

class BankHolidayTimesheetTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            openDialog: true,
            leave_name: '',
            leave_date: null,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        var uidForApi = user.id
        if (this.props.value) {
            uidForApi = this.props.value
        }
        Service.get(`/bb/api/bank/holiday/bank_holiday_timesheet/?uid=${uidForApi}&year=${this.props.year}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup[rowData[columnDef.field]];
                })
            );

            const builder = new CsvBuilder("Bank Holidays of " + this.props.year + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <MaterialTable
                        key={3}
                        title="Bank Holidays"
                        columns={[
                            {
                                title: 'Leave Name', field: 'name',
                            },
                            {
                                title: 'Leave Date', field: 'start__date', type: 'date', defaultSort: 'asc',
                                render: rowData => moment(rowData.start__date).format('DD MMM, ddd'),
                            },
                            {
                                title: 'Hours', field: 'actual_hours',
                            },
                            {
                                title: 'Comments', field: 'comments',
                            },
                            {
                                title: 'Is Optional', field: 'is_optional',
                                lookup: { false: 'Holiday', true: 'Optional' },
                            },
                            {
                                title: 'Status', field: 'is_approved',
                                lookup: { 0: 'Pending', 1: 'Approved', 2: "Rejected" },
                            },
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            paging: false,
                            padding: "dense",
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            exportButton: { csv: true },
                            exportCsv: (columns, data) => {
                                exportCsv(columns, data)
                            }
                        }}
                    />
                )}
            </div>
        )
    }
}

export default withSnackbar(BankHolidayTimesheetTable)
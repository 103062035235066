import React, { Component } from 'react'
import LMSDetailView from '../LMSDetailView';
import LeaveCard from '../LeaveCard';
import Header from '../../CommonUtils/Header';

class DetailView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null
        }
        this.child = React.createRef();
        this.handleData = this.handleData.bind(this);
        this.leavedetails = React.createRef();
    }

    handleData(data) {
        this.child.current.dataReceive(data);
        this.leavedetails.current.dataReceive(data);
    }
    render() {
        return (
            <div >
                {(this.props.match) ? (
                    <div>
                        <Header ref={this.child} />
                        <LeaveCard value={this.props.match.params.id} onHandleData={this.handleData} />
                    </div>
                ) : (null)
                }

                <LMSDetailView value={this.props.match.params.id} ref={this.leavedetails} />


            </div>
        )
    }
}

export default DetailView
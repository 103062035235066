import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { IconButton } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#007681',
        },
        secondary: {
            main: '#EEBE86',
        },
    }
})



class Header extends Component {
    constructor(props) {
        // console.log(props)
        super(props)
        this.state = {
            data: [],
        }
        this.dataReceive = this.dataReceive.bind(this);
    }

    dataReceive(data) {
        this.setState({
            data: data
        })
    }


    render() {

        const { classes } = this.props;

        const goback = () => {
            window.close()
        };

        return (
            (this.state.data.length) ? (
                <ThemeProvider theme={bbColorTheme}>
                    <div className={classes.root}>

                        <AppBar position="fixed">
                            <Toolbar>
                                <IconButton onClick={goback} color="inherit" edge="start">< CloseOutlinedIcon /></IconButton>
                                <Typography className={classes.title} noWrap>
                                    <Box display="flex" alignItems="flex-end" >
                                        <Box fontSize={24} marginLeft="30px" fontWeight="fontWeightBold">Leave Details Of {this.state.data[0].user_info.last_name + ", " + this.state.data[0].user_info.first_name}</Box>
                                    </Box>
                                </Typography>
                            </Toolbar>
                        </AppBar>

                    </div>
                </ThemeProvider>
            ) : null

        );
    }
}
export default withStyles(useStyles)(Header)

import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper'
import moment from "moment";
import Service from '../Networking/Networkingutils'
import errorMessage from '../Helper/ErrorMessage';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");
const localizer = momentLocalizer(moment)

class CalenderMini extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            start_date: moment().startOf('month').startOf('week'),
            end_date: moment().endOf('month').endOf('week'),
            BankLeavedata: []
        }
    }

    componentDidMount() {
        this.fetchData(this.state.start_date, this.state.end_date)
        Service.get(`/bb/api/bank/holiday/?year=${moment().format("YYYY")}&office_loc=${1}`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({
                    BankLeavedata: res.data,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchData(start, end) {
        var start_date = moment(start).format('DD-MM-YYYY HH:mm:ss')
        var end_date = moment(end).format('DD-MM-YYYY HH:mm:ss')

        Service.get(`/bb/api/team/tasks/?uid=${this.props.value}&start_date=${start_date}&end_date=${end_date}&tid=66,67,77,78`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var datas = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.is_submitted === 1) {
                        if (element.is_approved === 1) {
                            datas.push({
                                id: element.id,
                                title: element.uname_first + ", " + element.actual_hours + " hours - [ Approved ]",
                                start: new Date(element.start),
                                end: new Date(element.end),
                                is_approved: element.is_approved,
                                is_submitted: element.is_submitted,
                                tname: element.tname
                            })
                        }
                        else if (element.is_approved === 2) {
                            datas.push({
                                id: element.id,
                                title: element.uname_first + ", " + element.actual_hours + " hours - [ Rejected ]",
                                start: new Date(element.start),
                                end: new Date(element.end),
                                is_approved: element.is_approved,
                                is_submitted: element.is_submitted,
                                tname: element.tname
                            })
                        } else {
                            datas.push({
                                id: element.id,
                                title: element.uname_first + ", " + element.actual_hours + " hours - [ Requested ]",
                                start: new Date(element.start),
                                end: new Date(element.end),
                                is_approved: element.is_approved,
                                is_submitted: element.is_submitted,
                                tname: element.tname
                            })
                        }

                    } else if (element.is_submitted === 0 && element.is_approved === 2) {
                        datas.push({
                            id: element.id,
                            title: element.uname_first + ", " + element.actual_hours + " hours - [ Rejected ]",
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_approved: element.is_approved,
                            is_submitted: element.is_submitted,
                            tname: element.tname
                        })
                    } else {
                        datas.push({
                            id: element.id,
                            title: element.uname_first + ", " + element.actual_hours + " hours - [ Planned ]",
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_approved: 100,
                            is_submitted: element.is_submitted,
                            tname: element.tname
                        })
                    }
                }
                this.setState({
                    data: datas,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }
    onRangeChange = (range) => {
        if (range[0]) {
            this.setState({ start_date: moment(range[0]), end_date: moment(range[6]) });
            this.fetchData(moment(range[0]), moment(range[6]))
        } else {
            this.fetchData(range.start, range.end)
        }
    }
    render() {
        const customDayPropGetter = (date) => {
            var BankLeaveDates = this.state.BankLeavedata
            for (let i = 0; i < BankLeaveDates.length; i++) {
                const element = BankLeaveDates[i]
                var date1 = moment(date).format('YYYY-MM-DD')
                if (date1 === element.leave_date) {
                    return {
                        style: {
                            backgroundColor: '#ffe6e6',
                        },
                    }
                }
            }
        }
        
        return (
            <div>
                <Calendar
                    selectable='false'
                    localizer={localizer}
                    events={this.state.data}
                    defaultView={Views.MONTH}
                    views={[Views.MONTH, Views.WEEK]}
                    startAccessor="start"
                    endAccessor="end"
                    onRangeChange={(date) => this.onRangeChange(date)}
                    style={{ height: '77vh' }}
                    dayPropGetter={customDayPropGetter}
                    eventPropGetter={data => data.is_approved === 100 ? ({ style: { backgroundColor: '#005D99' }, }) : data.is_approved === 0 && data.is_submitted === 1 ? ({ style: { backgroundColor: '#f09e00' }, }) : data.is_approved === 1 ? ({ style: { backgroundColor: '#00aa39' }, }) : data.is_approved === 2 ? ({ style: { backgroundColor: '#585858' }, }) : (null)}
                />
            </div>
        )
    }
}

export default withSnackbar(CalenderMini)
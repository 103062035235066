import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper'
import MaterialTable from 'material-table'
import errorMessage from '../Helper/ErrorMessage';
import Service from '../Networking/Networkingutils'
import { CsvBuilder } from 'filefy';
import moment from "moment";
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");

class TableMini extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            lookup: []
        }
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData() {
        const lookupObj = {};
        Service.get(`/bb/api/team/tasks/?uid=${this.props.value}&tid=66,67,77,78`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.year = moment(element.start).format('YYYY')
                    lookupObj[element.year] = element.year;
                    if (element.is_submitted === 1) {
                        element.item_status = 0
                        if (element.is_approved !== 0) {
                            element.item_status = element.is_approved

                        }
                        data.push(element)
                    }
                    else if (element.is_submitted === 0 && element.is_approved === 2) {
                        element.item_status = element.is_approved
                        data.push(element)
                    } else {
                        element.item_status = 100
                        data.push(element)
                    }

                }

                this.setState({
                    data: data,
                    isLoading: false,
                    lookup: lookupObj
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }
    filterDate(date) {
        var Date = moment(date).format('DD-MM-YYYY');
        if (Date) {
            return Date;
        }
    }
    filterDay(end, start) {
        var Hours = ((moment.duration(moment(end).diff(moment(start))).asMinutes() / 60).toFixed(2));
        if (Hours) {
            return Hours.toString();
        }
    }
    render() {
        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup[rowData[columnDef.field]];
                })
            );

            const builder = new CsvBuilder(((this.props.userinfo) ? this.props.userinfo.last_name + ", " + this.props.userinfo.first_name + " Leaves " : "Leaves ") + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                <MaterialTable
                    title={(this.props.userinfo) ? this.props.userinfo.last_name + ", " + this.props.userinfo.first_name + " Leaves" : "Leaves"}
                    columns={[
                        {
                            title: 'Leave Date', field: 'start',
                            render: rowData => moment(rowData.start).format('DD-MM-YYYY'),
                            customFilterAndSearch: (term, rowData) => {
                                return (this.filterDate(rowData.start).includes(term))
                            }
                        },
                        {
                            title: 'Hours', field: 'start',
                            render: rowData => ((moment.duration(moment(rowData.end).diff(moment(rowData.start))).asMinutes() / 60).toFixed(2)),
                            customFilterAndSearch: (term, rowData) => {
                                return (this.filterDay(rowData.end, rowData.start).includes(term))
                            }
                        },
                        {
                            title: 'Year', field: 'year',
                            lookup: this.state.lookup
                        },
                        {
                            title: 'Leave Type', field: 'tname',
                            render: rowData => (rowData.tname),
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.tname).toLowerCase().includes(term.toLowerCase())
                            }
                        },
                        {
                            title: 'Status', field: 'item_status',
                            lookup: { 0: 'Requested', 1: 'Approved', 2: 'Rejected', 100: 'Planned' },
                        },

                    ]}

                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        paging: false,
                        pageSize: 10,
                        pageSizeOptions: [20, 50, 100],
                        actionsColumnIndex: -1,
                        filtering: true,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        }
                    }}

                />
            </div>
        )
    }
}

export default withSnackbar(TableMini)
import moment from 'moment';
import React, { Component } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import LvsSearchTable from './LvsSearchTable';
import Typography from '@material-ui/core/Typography';
import { LoopCircleLoading } from 'react-loadingg';
import Service from '../../Networking/Networkingutils'
import { getCookie } from '../../Helper/CookieHelper';
import errorMessage from '../../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Box } from '@material-ui/core';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class LvsSearchByDates extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dates: [],
            value: moment().format('YYYY-MM-DD'),
            isLoading: true,
            dateBasedCount: {},
            date: moment().format("MM-DD-YYYY"),
            ids: []
        }
    }

    componentDidMount() {
        var startDate = moment().subtract(3, 'year').calendar();
        var endDate = moment().add(6, 'month').calendar();

        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            if (moment(currentDate).isoWeekday() !== 6 && moment(currentDate).isoWeekday() !== 7) {
                dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        this.setState({ dates: dateArray, isLoading: false })
        this.fetchDates(startDate, endDate)
    }

    fetchDates(startDate, endDate) {
        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;
        if (access.access.l18a && access.access.l18a.l18a_c) {
            Service.get(`/bb/api/lms/timesheet/datebased_count/?start=${startDate}&end=${endDate}&tid=66,67,77,78`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        dateBasedCount: res.data
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)

                });
        } else {
            Service.get(`/bb/api/user/report/view/members_workload/?uid=${lead_id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var mem_list = []
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        var goon = true
                        for (let j = 0; j < mem_list.length; j++) {
                            const element1 = mem_list[j];
                            if (element.uid === element1.id) {
                                if (element.team_role === 1 || element.team_role === 2) {
                                    element1.is_lead = true
                                }
                                goon = false
                            }
                        }
                        if (goon) {
                            if (element.team_role === 1 || element.team_role === 2) {
                                element.uid_data.is_lead = true
                            }
                            mem_list.push(element.uid_data)
                        }
                    }
                    var ids = []
                    for (let i = 0; i < mem_list.length; i++) {
                        const element = mem_list[i];
                        if (!ids.includes(element.id)) {
                            ids.push(element.id)
                        }
                    }
                    this.setState({
                        ids: ids,
                    })
                    if (this.state.ids.length !== 0) {
                        Service.get(`/bb/api/lms/timesheet/datebased_count/?uid=${this.state.ids.toString()}&start=${startDate}&end=${endDate}&tid=66,67,77,78`, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                this.setState({
                                    dateBasedCount: res.data
                                })
                            })
                            .catch(error => {
                                console.log(error)
                                errorMessage(this.props, error)

                            });
                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue,
                date: moment(newValue).format('YYYY-MM-DD'),
                isLoading: true
            }, () => {
                this.setState({ isLoading: false })
            })
        };

        const handleDateChange = (event, newValue) => {
            if (newValue) {
                this.setState({
                    value: moment(newValue).format('YYYY-MM-DD'),
                    date: moment(newValue).format('YYYY-MM-DD'),
                    isLoading: true
                }, () => {
                    this.setState({ isLoading: false })
                })
            }
        };

        return (
            <div >
                <Box display="flex" justifyContent="center" >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Search dates"
                                format="MM/dd/yyyy"
                                value={this.state.date}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                minDate={moment().subtract(3, 'year').calendar()}
                                maxDate={moment().add(6, 'month').calendar()}
                                shouldDisableDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Box>
                <AppBar position="static" color="default" >
                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.dates.map((value, index) =>
                            <Tab value={value} key={index} label={moment(value).format('ddd, DD MMM YYYY')} icon={<Typography>{this.state.dateBasedCount[moment(value).format('DD-MM-YYYY')] === undefined ? 0 : this.state.dateBasedCount[moment(value).format('DD-MM-YYYY')]}</Typography>} {...a11yProps(index)} />
                        )}
                    </Tabs>
                </AppBar>
                {this.state.isLoading ? <LoopCircleLoading /> : <LvsSearchTable date={this.state.value} />}
            </div>
        )
    }
}

export default withSnackbar(LvsSearchByDates)

import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from '../../Networking/Networkingutils'
import errorMessage from '../../Helper/ErrorMessage'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper'
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Box } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LMSDetailView from '../LMSDetailView';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import LeaveCard from '../LeaveCard';
import { CircleToBlockLoading } from 'react-loadingg';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


const token = getCookie("bb_ts_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#007681',
        },
        secondary: {
            main: '#EEBE86',
        },
    }
})

class Management extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            userData: [],
            isLoading: true,
            lookup: [],
            user_details: null,
            user_sickleave: "",
            user_annualleave: "",
            openDialogue: false,
            openDialogue1: false,
            selectedData: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData(i) {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');

        const lookupObj = {};

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.lms.length; i++) {
                    const element = res.data.lms[i];
                    var id = element.user_info.id;
                    var username = element.user_info.last_name + ", " + element.user_info.first_name;
                    lookupObj[id] = username;
                }


                this.setState({
                    userData: res.data.users,
                    data: res.data.lms,
                    isLoading: false,
                    lookup: lookupObj,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    handleDataChange(e) {
        if (e.target.id === 'user Sickleave') {
            this.setState({ user_sickleave: e.target.value })
        } else if (e.target.id === 'user Annualleave') {
            this.setState({ user_annualleave: e.target.value })
        }
    }
    userCheck(datas) {

        if (datas.office_loc === 0) {
            return datas.last_name + ", " + datas.first_name + " (UK) ";

        } else {
            return datas.last_name + ", " + datas.first_name + " (IND) ";
        }
    }

    handleUserChange = (event, values) => {

        if (values !== null && values !== undefined) {
            this.setState({ user_details: values })
        }
        else {
            this.setState({ user_details: null })
        }
    }

    openDialog() {
        this.setState({ openDialogue1: true, user_details: null, user_sickleave: "", user_annualleave: "" })
    }

    postOps(e) {
        var fields = "";

        if (this.state.user_details === null) {
            fields += "User";
        } if (!this.state.user_sickleave) {
            fields += (!fields) ? "Sick/Casual" : ",Sick/Casual";
        } if (!this.state.user_annualleave) {
            fields += (!fields) ? "Annual" : ",Annual";
        }

        if (fields) {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
        } else {
            const data = {
                user_details: this.state.user_details.id,
                user_sickleave: this.state.user_sickleave,
                user_annualleave: this.state.user_annualleave,
            }
            Service.post('/bb/api/lms/leaveinfo/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.setState({ openDialogue1: false })
                    this.fetchData();
                    this.props.enqueueSnackbar('User Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {

        Service.put('/bb/api/lms/leaveinfo/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchData();
                this.props.enqueueSnackbar('User Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error);
            });
        // }
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false, openDialogue1: false })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <MaterialTable
                        title="Management"
                        columns={[
                            {
                                title: 'User', field: 'user_info.first_name',
                                render: rowData => rowData.user_info.last_name + ", " + rowData.user_info.first_name,
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.user_info.last_name + ", " + rowData.user_info.first_name).toLowerCase().includes(term.toLowerCase())
                                },
                                editable: 'never',
                            },
                            {
                                title: 'Emp Code', field: 'user_info.user_details.emp_code', defaultSort: 'asc',
                                editable: 'never',
                            },
                            {
                                title: 'CL (for this year)', field: 'user_sickleave', type: 'numeric',
                            },
                            {
                                title: 'CL Eligible(C)', field: 'available_sick', type: 'numeric', editable: 'never',
                                render: rowData => rowData.available_sick.toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.available_sick).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: 'CL Eligible(P)', field: 'this_month_sick_leave', type: 'numeric', editable: 'never',
                                render: rowData => rowData.this_month_sick_leave.toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_sick_leave).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: ' Availed CL', field: 'sick_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.sick_days).toFixed(2).includes(term)
                                }
                            },

                            {
                                title: 'CL Balance', field: 'user_sickleave', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.this_month_sick_leave - rowData.sick_days).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_sick_leave - rowData.sick_days).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var clbal1 = a.this_month_sick_leave - a.sick_days
                                    var clbal2 = b.this_month_sick_leave - b.sick_days
                                    return (clbal1 - clbal2).toFixed(2)
                                },
                            },
                            {
                                title: 'EL (for this year)', field: 'user_annualleave', type: 'numeric',
                            },
                            {
                                title: 'EL C/F', field: 'annaul_carry_forward', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.annaul_carry_forward).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.annaul_carry_forward).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: 'EL Eligible(C)', field: 'available_leave', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.available_leave + rowData.annaul_carry_forward).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.available_leave + rowData.annaul_carry_forward).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var elc1 = a.available_leave + a.annaul_carry_forward
                                    var elc2 = b.available_leave + b.annaul_carry_forward
                                    return (elc1 - elc2).toFixed(2)
                                },
                            },
                            {
                                title: 'EL Eligible(P)', field: 'this_month_annual_leave', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.this_month_annual_leave + rowData.annaul_carry_forward).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_annual_leave + rowData.annaul_carry_forward).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var elp1 = a.this_month_annual_leave + a.annaul_carry_forward
                                    var elp2 = b.this_month_annual_leave + b.annaul_carry_forward
                                    return (elp1 - elp2).toFixed(2)
                                },
                            },
                            {
                                title: 'Availed EL', field: 'leave_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.leave_days).toFixed(2).includes(term)
                                }
                            },

                            {
                                title: 'EL Balance', field: 'user_annualleave', type: 'numeric', validate: rowData => rowData.user_annualleave !== '', editable: 'never',
                                render: rowData => ((rowData.this_month_annual_leave + rowData.annaul_carry_forward) - rowData.leave_days).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return ((rowData.this_month_annual_leave + rowData.annaul_carry_forward) - rowData.leave_days).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var elb1 = (a.this_month_annual_leave + a.annaul_carry_forward) - a.leave_days
                                    var elb2 = (b.this_month_annual_leave + b.annaul_carry_forward) - b.leave_days
                                    return (elb1 - elb2).toFixed(2)
                                },
                            },
                            {
                                title: 'Maternity/ Paternity', field: 'maternity_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.maternity_days).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: 'LOP', field: 'lop_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.lop_days).toFixed(2).includes(term)
                                }
                            },

                        ]}

                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            // actionsColumnIndex: -1,
                            filtering: false,
                        }}
                        actions={[
                            {
                                icon: 'launch',
                                tooltip: 'New tab',
                                onClick: (event, rowData) => window.open("/detail/" + rowData.user_info.id)
                            },
                            {
                                icon: 'open_in_browser',
                                tooltip: 'Dialog popup',
                                onClick: (event, rowData) => { this.setState({ selectedData: rowData, openDialogue: true, }) }
                            },
                            {
                                icon: 'add',
                                tooltip: 'Add user',
                                isFreeAction: true,
                                onClick: (event) => this.openDialog()
                            },
                        ]}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        var fields = "";
                                        if (!newData.user_sickleave) {
                                            fields += "Sick/Casual";
                                        } if (!newData.user_annualleave) {
                                            fields += (!fields) ? "Annual" : ",Annual";
                                        }
                                        if (fields) {
                                            this.props.enqueueSnackbar(fields + ' is Empty!', {
                                                variant: 'warning'
                                            });
                                        } else {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                            this.putOps(newData);
                                        }
                                    }, 600);
                                }),
                        }}
                    />
                )}
                <div>
                    <Dialog fullScreen open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition}>
                        <ThemeProvider theme={bbColorTheme}>
                            <AppBar >
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleDialogueClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        Leave Details Of {(this.state.selectedData) ? (this.state.selectedData.user_info.last_name + ", " + this.state.selectedData.user_info.first_name) : (null)}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <LeaveCard values={(this.state.selectedData) ? (this.state.selectedData) : (null)} />
                            <LMSDetailView value={(this.state.selectedData) ? (this.state.selectedData.user_info.id) : (null)} leaveinfo2={this.state.selectedData ? this.state.selectedData : null} />
                        </ThemeProvider>
                    </Dialog>

                    <Dialog open={this.state.openDialogue1} onClose={handleDialogueClose} TransitionComponent={Transition} >
                        <DialogContent>

                            <Box marginTop="30px" fontSize={18} flexDirection="row" display="flex" justifyContent="center" fontWeight="bold">
                                Add User
                            </Box>

                            <Box marginTop="30px" flexDirection="row" display="flex" justifyContent="center">
                                <Autocomplete
                                    id="user"
                                    options={this.state.userData}
                                    getOptionLabel={(option) => this.userCheck(option)}
                                    type="name"
                                    style={{ width: 350 }}
                                    value={(this.state.user_details)}
                                    onChange={this.handleUserChange}
                                    renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />} />
                            </Box>

                            <Box marginTop="30px" display="flex" justifyContent="center" >
                                <TextField
                                    variant="outlined"
                                    id="user Sickleave"
                                    label="Sick/Casual"
                                    type="number"
                                    disable
                                    style={{ width: 350 }}
                                    value={this.state.user_sickleave}
                                    onChange={e => this.handleDataChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>

                            <Box marginTop="30px" display="flex" justifyContent="center" >
                                <TextField
                                    variant="outlined"
                                    id="user Annualleave"
                                    label="Annual"
                                    type="number"
                                    style={{ width: 350 }}
                                    onChange={e => this.handleDataChange(e)}
                                    value={this.state.user_annualleave}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Box>

                        </DialogContent>

                        <DialogActions>
                            <Box display="flex" justifyContent="center" marginTop="10px" marginBottom="10px">

                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => this.postOps(e)}>Add</Button>

                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>Cancel</Button>

                            </Box>
                        </DialogActions>
                    </Dialog>
                </div>
            </div >
        )
    }
}

export default withSnackbar(Management);

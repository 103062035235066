import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/Networkingutils'
import moment from "moment";
import errorMessage from '../../Helper/ErrorMessage';
import MaterialTable from 'material-table'
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, IconButton } from '@material-ui/core';
import { Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LaunchIcon from '@material-ui/icons/Launch';
import Tooltip from '@material-ui/core/Tooltip';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class LvsSearchTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ids: [],
            isLoading: true,
            isLoading1: true,
            isLoading2: true,
            data: [],
            data1: [],
            openDialogue: false,
            selectedData: null,
            isProgress: true,
            tabledata: [],
        }
    }

    componentDidMount() {
        this.fetchData(this.props.date)
    }

    fetchData(date) {
        var start_date = moment(date).startOf('day').format('DD-MM-YYYY HH:mm:ss')
        var end_date = moment(date).endOf('day').format('DD-MM-YYYY HH:mm:ss')

        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;

        Service.get(`/bb/api/user/report/view/members_workload/?uid=${lead_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var mem_list = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var goon = true
                    for (let j = 0; j < mem_list.length; j++) {
                        const element1 = mem_list[j];
                        if (element.uid === element1.id) {
                            if (element.team_role === 1 || element.team_role === 2) {
                                element1.is_lead = true
                            }
                            goon = false
                        }
                    }
                    if (goon) {
                        if (element.team_role === 1 || element.team_role === 2) {
                            element.uid_data.is_lead = true
                        }
                        mem_list.push(element.uid_data)
                    }
                }
                var ids = []
                for (let i = 0; i < mem_list.length; i++) {
                    const element = mem_list[i];
                    if (!ids.includes(element.id)) {
                        ids.push(element.id)
                    }
                }

                this.setState({
                    ids: ids,
                    isLoading: false,
                    isLoading1: false
                })

                if (this.state.ids.length !== 0) {
                    if (access.access.l18a && access.access.l18a.l18a_c) {
                        Service.get(`/bb/api/team/tasks/?start_date=${start_date}&end_date=${end_date}&tid=66,67,77,78&office_loc=1`, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                for (let i = 0; i < res.data.length; i++) {
                                    const element = res.data[i];
                                    element.isLoading = false
                                    element.isButtonDisabled = false
                                    element.isButtonDisabled1 = false
                                    if (element.is_submitted === 0 && element.is_approved === 0) {
                                        element.status = 100
                                    } else if (element.is_approved === 0 && element.is_submitted === 1) {
                                        element.status = 0
                                    } else if (element.is_approved === 1 && element.is_submitted === 1) {
                                        element.status = 1
                                    } else if (element.is_approved === 2) {
                                        element.status = 2
                                    }
                                }
                                this.setState({
                                    data: res.data,
                                    isLoading: false,
                                    isLoading1: false,
                                })
                            })
                            .catch(error => {
                                console.log(error)
                                errorMessage(this.props, error)

                            });
                        this.lmsapproval()
                    } else {
                        Service.get(`/bb/api/team/tasks/?uid=${this.state.ids.toString()}&start_date=${start_date}&end_date=${end_date}&tid=66,67,77,78`, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                for (let i = 0; i < res.data.length; i++) {
                                    const element = res.data[i];
                                    element.isLoading = false
                                    element.isButtonDisabled = false
                                    element.isButtonDisabled1 = false
                                    if (element.is_submitted === 0 && element.is_approved === 0) {
                                        element.status = 100
                                    } else if (element.is_approved === 0 && element.is_submitted === 1) {
                                        element.status = 0
                                    } else if (element.is_approved === 1 && element.is_submitted === 1) {
                                        element.status = 1
                                    } else if (element.is_approved === 2) {
                                        element.status = 2
                                    }
                                }
                                this.setState({
                                    data: res.data,
                                    isLoading: false,
                                    isLoading1: false,
                                })
                            })
                            .catch(error => {
                                console.log(error)
                                errorMessage(this.props, error)

                            });
                        this.lmsapproval()
                    }
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    lmsapproval() {
        Service.get(`/bb/api/lms/approval/lms_leave_details/?user=${this.state.ids.toString()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let j = 0; j < res.data.length; j++) {
                    const element = res.data[j];
                    element.isLoading = false;
                    element.isButtonDisabled = false;
                    element.isButtonDisabled1 = false;
                }

                this.setState({
                    data1: res.data,
                    isLoading: false,
                    isLoading1: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            })
    }

    isReject(date) {
        var date1 = moment();
        var date2 = moment(date).utc().startOf('date').add(15, 'days');
        return date1.isAfter(date2);
    }

    approval = (event, data) => {
        var lists = this.state.data1
        var card_array = [];
        data.is_approved = 1;
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks === data.id) {
                card_array.push(lists[i].id)
                lists[i].isLoading = true;
            }
        }

        this.setState({ data1: lists, isLoading2: true })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 1,
            },
        })
            .then(res => {
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].team_tasks === data.id) {
                        lists[i].isLoading = false;
                    }
                }
                this.setState({ data1: lists, isLoading2: false })
                this.props.enqueueSnackbar('Approved Successfully', {
                    variant: 'success'
                });
                this.lmsapproval()
                this.showButtons(data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    reject = (event, data) => {
        var lists = this.state.data1
        var card_array = [];
        data.is_approved = 2;
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks === data.id) {
                card_array.push(lists[i].id)
                lists[i].isLoading = true;
            }
        }

        this.setState({ data1: lists, isLoading2: true })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 2,
            },
        })
            .then(res => {
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].team_tasks === data.id) {
                        lists[i].isLoading = false;
                    }
                }
                this.setState({ data1: lists, isLoading2: false })
                this.props.enqueueSnackbar('Rejected Successfully', {
                    variant: 'success'
                });
                this.lmsapproval()
                this.showButtons(data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    showButtons = (data) => {
        var lists = this.state.data1
        var is_exist = false
        var name = ''
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].team_tasks !== null) {
                if (lists[i].team_tasks === data.id) {
                    is_exist = true;
                    name = lists[i].replied_by_data ? lists[i].replied_by_data.first_name : null
                    break
                }
            } else if (lists[i].snapshot.id === data.id) {
                name = lists[i].replied_by_data ? lists[i].replied_by_data.first_name : null
                is_exist = true
                break
            }
        }
        if (is_exist) {
            if (data.is_approved === 0) {
                return <Box display="flex" justifyContent="center" bgcolor="background.paper">
                    <Button
                        disabled={data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        onClick={e => this.approval(e, data)}>
                        Approve
                    </Button>

                    <Button
                        disabled={this.isReject(data.start) || data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        onClick={e => this.reject(e, data)}>
                        Reject
                    </Button>
                </Box>
            } else if (data.is_approved === 1) {
                return (
                    <Box display="flex" justifyContent="center" >

                        <Box display="flex" alignItems="center" >
                            <Box style={{ color: "green" }} marginTop="5px"><CheckCircleIcon /></Box>
                            <Box style={{ color: "green" }} marginLeft="5px">Approved by {name}</Box>
                        </Box>

                        {(this.isReject(data.start)) ? (null) : (
                            <Button
                                disabled={this.isReject(data.start) || data.isButtonDisabled === true || data.isButtonDisabled1 === true}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                                onClick={e => this.reject(e, data)}>
                                Reject
                            </Button>
                        )}
                    </Box>
                )
            } else if (data.is_approved === 2) {
                return <Box display="flex" justifyContent="center" >
                    <Box display="flex" alignItems="center" >
                        <Box style={{ color: "red" }} marginTop="5px"><CancelIcon /></Box>
                        <Box style={{ color: "red" }} marginLeft="5px">Rejected by {name}</Box>
                    </Box>
                </Box>
            }
        } else {
            return <Box display="flex" justifyContent="center" >
                <Box display="flex" alignItems="center" >
                    <Box marginBottom="1px" marginLeft="3px">Not Yet Submitted </Box>
                </Box>
            </Box>
        }
    }

    fetchID(id) {
        Service.get(`/bb/api/lms/approval/?team_tasks=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ tabledata: res.data, isProgress: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    linkButton = (value, data) => {
        if (data !== null && data !== undefined) {
            var u_id = value.uid;
            window.open("/detail/" + u_id)
            this.setState({ openDialogue: false })
        } else if (data === undefined && value.is_approved === 2) {
            this.props.enqueueSnackbar('This Leave request is Rejected !', {
                variant: 'info'
            });
        } else {
            this.props.enqueueSnackbar('Not Yet Submitted', {
                variant: 'info'
            });
        }
    }

    render() {

        const handleOpenDialogue = (data) => {
            this.setState({ openDialogue: true, selectedData: data, isLoading2: false })
            this.fetchID(data.id)
        }

        const handleCloseDialogue = () => {
            this.setState({ openDialogue: false })
        }

        return (
            <div>
                <MaterialTable
                    title={"Leave by Dates"}
                    columns={[
                        {
                            title: 'User', field: 'uname_first',
                            render: rowData => rowData.uname_last + ", " + rowData.uname_first,
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.uname_last + ", " + rowData.uname_first).toLowerCase().includes(term.toLowerCase())
                            },
                            editable: 'never',
                        },
                        {
                            title: 'Leave Type', field: 'tname',
                            render: rowData => (rowData.tname),
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.tname).toLowerCase().includes(term.toLowerCase())
                            }
                        },
                        {
                            title: 'Hours', field: 'actual_hours', type: 'numeric', editable: 'never',
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.actual_hours).toFixed(2).includes(term)
                            }
                        },
                        {
                            title: 'Status', field: 'status',
                            lookup: { 0: 'Requested', 1: 'Approved', 2: 'Rejected', 100: 'Planned' },
                        },
                        {
                            title: 'Comments', field: 'comments',
                        },
                    ]}

                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        paging: false,
                        pageSize: 10,
                        pageSizeOptions: [20, 50, 100],
                        actionsColumnIndex: -1,
                        filtering: true,
                    }}
                    actions={([
                        // rowData => ({
                        //     icon: 'launch',
                        //     tooltip: 'Newtab',
                        //     onClick: (event, rowData) => window.open("/detail/" + rowData.uid_data.id),
                        //     disabled: rowData.is_submitted === 0 || rowData.is_approved === 2
                        // }),
                        rowData => ({
                            icon: 'info',
                            tooltip: 'Approve/Reject',
                            onClick: (event, rowData) => handleOpenDialogue(rowData),
                            disabled: rowData.is_submitted === 0 || rowData.is_approved === 2
                        }),
                    ])} />

                <div>
                    <Dialog open={this.state.openDialogue} onClose={handleCloseDialogue} TransitionComponent={Transition}>
                        <DialogTitle id="customized-title" onClose={handleCloseDialogue}>
                            Leave Details
                        </DialogTitle>
                        <DialogContent>
                            {(this.state.selectedData) ? (
                                <div>
                                    <Typography>
                                        <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="5px" >
                                            <Box fontWeight="fontWeightBold">Name: </Box>
                                            <Box>
                                                {this.state.selectedData.uname_last + ", " + this.state.selectedData.uname_first}
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="10px">
                                            <Box fontWeight="fontWeightBold">Leave Type : </Box>
                                            <Box>
                                                {this.state.selectedData.tname}
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box display="flex" flexDirection="row" style={{ width: 500 }} marginTop="10px">
                                            <Box fontWeight="fontWeightBold">Hours : </Box>
                                            <Box>
                                                {this.state.selectedData.actual_hours} hours
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box display="flex" flexDirection="row" style={{ width: 250 }} marginTop="10px">
                                            <Box fontWeight="fontWeightBold">Comments : </Box>
                                            <Box>
                                                {this.state.selectedData.comments}
                                            </Box>
                                        </Box>
                                    </Typography>

                                    <Box display="flex" marginTop="15px" bgcolor="background.paper" justifyContent='center'>
                                        <Box display="flex" flexDirection="row" justifyContent="center" marginLeft="5px" >
                                            {(this.state.isLoading2) ? <CircularProgress color='primary' size={25} style={{ marginTop: '10px' }} /> : this.state.buttonLoading ? <CircularProgress color='primary' size={25} style={{ marginTop: '10px' }} /> : this.showButtons(this.state.selectedData)}
                                        </Box>
                                        <Box display="flex" flexDirection="row" justifyContent="center" marginLeft="10px">
                                            {this.state.isProgress === true ? <CircularProgress color="secondary" /> : (
                                                <Tooltip title="GO TO">
                                                    <IconButton color="primary" onClick={e => this.linkButton(this.state.selectedData, this.state.tabledata[0])} >
                                                        <LaunchIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Box>
                                </div>
                            ) : (null)}
                        </DialogContent>

                        {(this.state.selectedData) ? (
                            <DialogActions >
                                <Button onClick={handleCloseDialogue} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        ) : (null)}

                    </Dialog>
                </div>

            </div>
        )
    }
}
export default withSnackbar(LvsSearchTable)
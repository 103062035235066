import axios from "axios";


const host_name = window.location.hostname
let domain = null

if (host_name.includes('bbparam.com')) {
  domain = 'https://api.bbparam.com'

} else {
  domain = 'http://shweetha-dev.centralindia.cloudapp.azure.com:8003'
}

export function getApiDomain() {
  return domain
}

class Service {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };

  }


  get(path, options) {

    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  patch(path, options) {

    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update(path, options) {

    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post(path, options) {

    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put(path, options) {

    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete(path, options) {

    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }


}

export default new Service();
import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from '../../Networking/Networkingutils'
// import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper'
import errorMessage from '../../Helper/ErrorMessage';
import { CircleToBlockLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { CsvBuilder } from 'filefy';


const token = getCookie("bb_ts_token");

class LogTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tabledata: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        Service.get(`/bb/api/lms/approval/logs/?year=${this.props.year}&approval=0,1`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({
                    tabledata: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    // filterDate(date) {
    //     var Date = moment(date).format('DD-MM-YYYY');
    //     if (Date) {
    //         return Date;
    //     }
    // }

    // filterComments(comments) {
    //     if (comments === null) {
    //         return '-';
    //     } else {
    //         return comments;
    //     }
    // }

    // filterDay(end, start) {
    //     var Day = ((moment.duration(moment(end).diff(moment(start))).asMinutes() / 60).toFixed(2) / 8);
    //     if (Day) {
    //         return Day.toString();
    //     }
    // }

    // filterRepliedby(replied_by) {
    //     if (!replied_by) {
    //         return '-';
    //     } else {
    //         return replied_by;
    //     }
    // }

    // filterRepliedon(replied_on) {
    //     var Replied_on = moment(replied_on).format('DD-MM-YYYY');
    //     if (!Replied_on) {
    //         return '-';
    //     } else {
    //         return Replied_on;
    //     }
    // }

    render() {

        const exportCsv = (columnList, initialData) => {

            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    console.log(columnDef.lookup)
                    // return columnDef.render ? columnDef.render(rowData) : columnDef.lookup[rowData[columnDef.field]];
                    return rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("Log report for the year " + this.props.year + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (

                    <MaterialTable
                        title="Log"
                        columns={[
                            {
                                title: 'User', field: 'user_name',
                            },
                            {
                                title: 'Team', field: 'gname',
                            },
                            {
                                title: 'Receiver', field: 'requested_to',
                            },
                            {
                                title: 'Leave Type', field: 'tname',
                            },
                            {
                                title: 'Date', field: 'start', type: 'date',
                            },
                            {
                                title: 'Comments', field: 'comments',
                            },
                            {
                                title: 'Duration', field: 'duration_in_hours',
                                render: rowData => rowData.duration_in_hours + " hours (" + rowData.duration_in_days + " day)"
                            },
                            // {
                            //     title: 'Duration(Weeks)', field: 'duration_in_week',
                            // },
                            {
                                title: 'Status', field: 'status',
                                // lookup: { 1: 'Approved', 0: 'Pending', 2: 'Rejected' }
                            },
                            {
                                title: 'Replied by', field: 'replied_by',
                            },
                            {
                                title: 'Replied on', field: 'replied_on', type: 'date',
                            },
                        ]}

                        data={this.state.tabledata}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            pageSize: 500,
                            pageSizeOptions: [500, 750, 1000],
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: { csv: true },
                            exportCsv: (columns, data) => {
                                exportCsv(columns, data)
                            }
                        }}
                        actions={[
                            {
                                icon: 'launch',
                                tooltip: 'New tab',
                                onClick: (event, rowData) => window.open("/detail/" + rowData.user_id)
                            },
                        ]}
                    />
                )}
            </div>
        )
    }
}

export default withSnackbar(LogTable);
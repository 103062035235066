import React, { Component } from 'react'
import MaterialTable from 'material-table';
import { CircleToBlockLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/Networkingutils'
import errorMessage from '../Helper/ErrorMessage';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CsvBuilder } from 'filefy';

const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class BankHolidaysTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            openDialog: true,
            leave_name: '',
            leave_date: null,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/bank/holiday/?year=${this.props.year}&&office_loc=${this.props.off_loc}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    openDialog() {
        this.setState({ openDialogue: true, leave_name: '', leave_date: null })
    }

    postOps(e) {
        var fields = "";

        if (!this.state.leave_name) {
            fields += "Leave Name";
        } if (!this.state.leave_date) {
            fields += (!fields) ? "Leave Date" : ",Leave Date";
        }

        if (fields) {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
        } else {
            const data = {
                name: this.state.leave_name,
                year: moment(this.state.leave_date).format('YYYY'),
                leave_date: moment(this.state.leave_date).format('YYYY-MM-DD'),
                created_by: user.id,
                date_created: new Date(),
                office_loc: this.props.off_loc
            }
            Service.post(`/bb/api/bank/holiday/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.setState({ openDialogue: false })
                    this.fetchData();
                    this.props.enqueueSnackbar('Bank Holiday Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {

        data.year = moment(data.leave_date).format('YYYY')
        data.leave_date = moment(data.leave_date).format('YYYY-MM-DD')

        Service.put('/bb/api/bank/holiday/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchData();
                this.props.enqueueSnackbar('Bank Holiday Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error);
            });
    }

    handleDataChange(e) {
        if (e.target.id === 'leave name') {
            this.setState({ leave_name: e.target.value })
        }
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false })
        };

        const handleDateChange = (date) => {
            this.setState({
                leave_date: date
            })
        };
        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup[rowData[columnDef.field]];
                })
            );

            const builder = new CsvBuilder("Bank Holidays of " + this.props.year + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <MaterialTable
                        key={3}
                        title="Bank Holidays"
                        columns={[
                            {
                                title: 'Leave Name', field: 'name',
                                render: rowData => rowData.name
                            },
                            {
                                title: 'Year', field: 'year', editable: 'never',
                                render: rowData => rowData.year
                            },
                            {
                                title: 'Leave Date', field: 'leave_date', type: 'date', defaultSort: 'asc',
                                render: rowData => moment(rowData.leave_date).format('DD MMM, ddd'),
                            },
                            {
                                title: 'Office Location', field: 'office_loc', editable: 'never',
                                lookup: { 0: 'UK', 1: 'IND' },
                            },
                            {
                                title: 'Is Optional', field: 'is_optional',
                                lookup: { false: 'Holiday', true: 'Optional' },
                            },
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            actionsColumnIndex: -1,
                            paging: false,
                            padding: "dense",
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            exportButton: { csv: true },
                            exportCsv: (columns, data) => {
                                exportCsv(columns, data)
                            }
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Leave',
                                isFreeAction: true,
                                onClick: (event) => this.openDialog(),
                                hidden: this.props.newTab === true
                            },
                        ]}
                        editable={{
                            onRowUpdate: this.props.newTab === true ? null : (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        var fields = "";
                                        if (!newData.name) {
                                            fields += "Leave Name";
                                        } if (!newData.leave_date) {
                                            fields += (!fields) ? "Leave Date" : ",Leave Date";
                                        }

                                        if (fields) {
                                            this.props.enqueueSnackbar(fields + ' is Empty!', {
                                                variant: 'warning'
                                            });
                                        } else {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                            this.putOps(newData);
                                        }
                                    }, 600);
                                }),
                        }}
                    />
                )}
                <div>
                    <Dialog open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition} >
                        <DialogContent>

                            <Box marginTop="30px" fontSize={18} flexDirection="row" display="flex" justifyContent="center" fontWeight="bold">
                                Add Bank Holiday
                            </Box>

                            <Box marginTop="30px" display="flex" justifyContent="center">
                                <TextField
                                    variant='outlined'
                                    id="leave name"
                                    label="Leave Name"
                                    style={{ width: 350 }}
                                    value={this.state.leave_name}
                                    onChange={e => this.handleDataChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box marginTop="30px" display="flex" justifyContent="center" >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="outlined"
                                        margin="normal"
                                        id="date"
                                        label="Leave Date"
                                        format="dd/MM/yyyy"
                                        style={{ width: 350 }}
                                        minDate={moment(this.props.year.toString()).startOf('year').format('YYYY-MM-DD')}
                                        maxDate={moment(this.props.year.toString()).endOf('year').format('YYYY-MM-DD')}
                                        value={this.state.leave_date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>

                        </DialogContent>

                        <DialogActions>
                            <Box display="flex" justifyContent="center" marginTop="10px" marginBottom="10px">

                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => this.postOps(e)}>Add</Button>

                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>Cancel</Button>

                            </Box>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default withSnackbar(BankHolidaysTable)
import React, { Component } from 'react'
import LMSDetailView from '../LMSDetailView';
import LeaveCard from '../LeaveCard';
import Header from '../../CommonUtils/Header';
import { getCookie } from '../../Helper/CookieHelper'
import { Box } from '@material-ui/core';

const user = JSON.parse(getCookie("bb_ts_user"));
// const receiver_id = user.id;

class RequestLink extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null
        }
        this.child = React.createRef();
        this.leavedetails = React.createRef();
        this.handleData = this.handleData.bind(this);
    }

    handleData(data) {
        this.child.current.dataReceive(data);
        this.leavedetails.current.dataReceive(data);
    }

    userCheck() {
        if (user.id === parseInt(this.props.match.params.r_id)) {
            return true
        } else {
            return false
        }

    }
    render() {
        return (
            this.userCheck() ?
                <div >
                    {(this.props.match) ? (
                        <div>
                            <Header ref={this.child} />
                            <LeaveCard value={this.props.match.params.u_id} onHandleData={this.handleData} />
                            <LMSDetailView ref={this.leavedetails} user_id={this.props.match.params.u_id} lead_id={this.props.match.params.r_id} check_lead={true}/>
                        </div>
                    ) : (null)
                    }
                </div>
                :
                <div style={{ position: 'absolute', top: 0, width: '100%', marginTop: '25%', color: 'red' }}>
                    <Box flexDirection="row" maxHeight={300} display="flex" justifyContent="center" fontWeight="bold" fontSize={22}>
                        Sorry!...You don't have Access to this Page
                    </Box>
                </div>
        )
    }
}

export default RequestLink
import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from '../../Networking/Networkingutils'
import errorMessage from '../../Helper/ErrorMessage'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper'
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Badge, Box, Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LMSDetailView from '../LMSDetailView';
import LeaveCard from '../LeaveCard';
import { CircleToBlockLoading } from 'react-loadingg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const token = getCookie("bb_ts_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Team extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tabledata: [],
            isLoading: true,
            lookup: [],
            openDialogue: false,
            selectedData: null,
            dataIndex: null,
            prevDisabled: false,
            nextDisabled: false,
            dialogContent: true,
            clickAction: false
        }
        this.handleAction = this.handleAction.bind(this)
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ isLoading: true })
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');
        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;
        const lookupObj = {};

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?rid=${lead_id}&start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = res.data;
                data.sort(function (a, b) {
                    return a.user_info.user_details.emp_code - b.user_info.user_details.emp_code;
                });
                this.setState({
                    tabledata: data,
                    isLoading: false,
                    lookup: lookupObj,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)

            });

    }

    prevNextChange(action) {
        this.setState({ dialogContent: true })
        setTimeout(() => {
            var selectedData = this.state.selectedData
            var data = this.state.tabledata
            var dataIndex = this.state.dataIndex

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.id === selectedData.id) {
                    this.setState({ dataIndex: i })
                    break
                }
            }
            if (action === 'prev') {
                dataIndex = dataIndex - 1
                selectedData = data[dataIndex]
                this.setState({ selectedData: selectedData, dialogContent: false, dataIndex, prevDisabled: dataIndex === 0 ? true : false, nextDisabled: dataIndex === (data.length - 1) ? true : false })
            } else if (action === 'next') {
                dataIndex = dataIndex + 1
                selectedData = data[dataIndex]
                this.setState({ selectedData: selectedData, dialogContent: false, dataIndex, prevDisabled: dataIndex === 0 ? true : false, nextDisabled: dataIndex === (data.length - 1) ? true : false })
            }
        }, 500);

    }

    handleAction(childData) {
        this.setState({
            clickAction: childData
        })
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false })
            if (this.state.clickAction) {
                this.setState({ clickAction: false })
                this.fetchData()
            }
        };


        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <MaterialTable
                        title="Team"
                        columns={[
                            {
                                title: 'User', field: 'user_info.first_name',
                                render: rowData => rowData.user_info.last_name + ", " + rowData.user_info.first_name,
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.user_info.last_name + ", " + rowData.user_info.first_name).toLowerCase().includes(term.toLowerCase())
                                },
                                editable: 'never',
                            },
                            {
                                title: 'Emp Code', field: 'user_info.user_details.emp_code', defaultSort: 'asc',
                                editable: 'never',
                            },
                            {
                                title: 'CL (for this year)', field: 'user_sickleave', type: 'numeric',
                            },
                            // {
                            //     title: 'CL Eligible(C)', field: 'available_sick', type: 'numeric', editable: 'never',
                            //     render: rowData => rowData.available_sick.toFixed(2),
                            //     customFilterAndSearch: (term, rowData) => {
                            //         return (rowData.available_sick).toFixed(2).includes(term)
                            //     }
                            // },
                            {
                                title: 'CL Eligible', field: 'this_month_sick_leave', type: 'numeric', editable: 'never',
                                render: rowData => rowData.this_month_sick_leave.toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_sick_leave).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: ' Availed CL', field: 'sick_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.sick_days).toFixed(2).includes(term)
                                }
                            },

                            {
                                title: 'CL Balance', field: 'user_sickleave', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.this_month_sick_leave - rowData.sick_days).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_sick_leave - rowData.sick_days).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var clbal1 = a.this_month_sick_leave - a.sick_days
                                    var clbal2 = b.this_month_sick_leave - b.sick_days
                                    return (clbal1 - clbal2).toFixed(2)
                                },
                            },
                            {
                                title: 'EL (for this year)', field: 'user_annualleave', type: 'numeric',
                            },
                            {
                                title: 'EL C/F', field: 'annaul_carry_forward', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.annaul_carry_forward).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.annaul_carry_forward).toFixed(2).includes(term)
                                }
                            },
                            // {
                            //     title: 'EL Eligible(C)', field: 'available_leave', type: 'numeric', editable: 'never',
                            //     render: rowData => (rowData.available_leave + rowData.annaul_carry_forward).toFixed(2),
                            //     customFilterAndSearch: (term, rowData) => {
                            //         return (rowData.available_leave + rowData.annaul_carry_forward).toFixed(2).includes(term)
                            //     }
                            // },
                            {
                                title: 'EL Eligible', field: 'this_month_annual_leave', type: 'numeric', editable: 'never',
                                render: rowData => (rowData.this_month_annual_leave + rowData.annaul_carry_forward).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.this_month_annual_leave + rowData.annaul_carry_forward).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var elp1 = a.this_month_annual_leave + a.annaul_carry_forward
                                    var elp2 = b.this_month_annual_leave + b.annaul_carry_forward
                                    return (elp1 - elp2).toFixed(2)
                                },
                            },
                            {
                                title: 'Availed EL', field: 'leave_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.leave_days).toFixed(2).includes(term)
                                }
                            },

                            {
                                title: 'EL Balance', field: 'user_annualleave', type: 'numeric', validate: rowData => rowData.user_annualleave !== '', editable: 'never',
                                render: rowData => ((rowData.this_month_annual_leave + rowData.annaul_carry_forward) - rowData.leave_days).toFixed(2),
                                customFilterAndSearch: (term, rowData) => {
                                    return ((rowData.this_month_annual_leave + rowData.annaul_carry_forward) - rowData.leave_days).toFixed(2).includes(term)
                                },
                                customSort: (a, b) => {
                                    var elb1 = (a.this_month_annual_leave + a.annaul_carry_forward) - a.leave_days
                                    var elb2 = (b.this_month_annual_leave + b.annaul_carry_forward) - b.leave_days
                                    return (elb1 - elb2).toFixed(2)
                                },
                            },
                            {
                                title: 'Maternity/ Paternity', field: 'maternity_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.maternity_days).toFixed(2).includes(term)
                                }
                            },
                            {
                                title: 'LOP', field: 'lop_days', type: 'numeric', editable: 'never',
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.lop_days).toFixed(2).includes(term)
                                }
                            },

                        ]}
                        data={this.state.tabledata}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            // actionsColumnIndex: -1,
                            filtering: false
                        }}
                        actions={[
                            {
                                icon: 'launch',
                                tooltip: 'Newtab',
                                onClick: (event, rowData) => window.open("/detail/" + rowData.user_info.id)
                            },
                            rowData => ({
                                icon: () => <Badge badgeContent={rowData.pending_leaves} color="primary"><OpenInBrowserIcon /></Badge>,
                                tooltip: 'Dialoguepopup',
                                onClick: (event, rowData) => {
                                    this.setState({ clickAction: false })
                                    var selectedData = rowData
                                    var data = this.state.tabledata
                                    for (let i = 0; i < data.length; i++) {
                                        const element = data[i];
                                        if (element.id === selectedData.id) {
                                            this.setState({ dataIndex: i }, () => {
                                                this.setState({
                                                    selectedData: selectedData,
                                                    openDialogue: true,
                                                    dialogContent: false,
                                                    prevDisabled: this.state.dataIndex === 0 ? true : false,
                                                    nextDisabled: (this.state.dataIndex === (this.state.tabledata.length - 1)) ? true : false
                                                })
                                            })
                                            break
                                        }
                                    }
                                }
                            }),
                        ]}
                    />
                )}
                <div>
                    <Dialog fullScreen open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition}>
                        <AppBar >
                            <Toolbar>
                                <Box display='flex' flexDirection={'row'} width={'100%'} alignItems='center'>
                                    <Box flexGrow={1} display='flex' flexDirection={'row'} alignItems='center'>
                                        <IconButton edge="start" color="inherit" onClick={handleDialogueClose} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" >
                                            Leave Details Of {(this.state.selectedData) ? (this.state.selectedData.user_info.last_name + ", " + this.state.selectedData.user_info.first_name) : (null)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Button variant="contained" color="secondary" size="small" startIcon={<ArrowBackIosIcon />} style={{ marginRight: '10px' }} disabled={this.state.prevDisabled} onClick={() => this.prevNextChange('prev')}>Prev</Button>
                                        <Button variant="contained" color="secondary" size="small" endIcon={<ArrowForwardIosIcon />} disabled={this.state.nextDisabled} onClick={() => this.prevNextChange('next')}>Next</Button>
                                    </Box>

                                </Box>
                            </Toolbar>
                        </AppBar>
                        {this.state.dialogContent ? <CircleToBlockLoading color='#007681' /> :
                            <div>
                                <LeaveCard values={(this.state.selectedData) ? (this.state.selectedData) : (null)} />
                                <LMSDetailView action={this.handleAction} value={(this.state.selectedData) ? (this.state.selectedData.user_info.id) : (null)} leaveinfo2={this.state.selectedData ? this.state.selectedData : null} />
                            </div>
                        }
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default withSnackbar(Team);

import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BankHolidaysTable from '../BankHolidaysTable';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class BankHolidays extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            years: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        var years = []

        for (let index = 2020; index <= moment().year() + 1; index++) {
            years.push(index)
        }

        this.setState({
            years: years,
            value: years.length - 2
        })
    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };

        return (
            <div>
                <AppBar position="static" color="default">
                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.years.map((value, index) =>
                            <Tab label={this.state.years[index]} {...a11yProps(index)} />
                        )}
                    </Tabs>
                </AppBar>
                {this.state.years.map((value, index) =>
                    <TabPanel value={this.state.value} index={index}>
                        <BankHolidaysTable year={this.state.years[index]} newTab={this.props.newTab === true ? true : false} off_loc={this.props.off_loc}/>
                    </TabPanel>
                )}
            </div>
        )
    }
}

export default withSnackbar(BankHolidays)
